import React from "react"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import slug from "slug"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Style(s) */
import "./edit-header.scss"

const EditHeader = ({ location, title, date, byline }) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="post-header edit-header">
      <section className="title-section-full">
        <h1 className="text--white">{format(parseISO(date), "d MMMM yyyy")}</h1>
        <h2>{title}</h2>
        <h3 className="text--white">{byline}</h3>  
      </section>
      <Link className="close" to={(prevUrlPath && !prevUrlPath.includes('edits')) ? prevUrlPath : "/edits"}>
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>

    </header>
  )
}

export default EditHeader
